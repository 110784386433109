import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/index',
      name: 'Index',
      meta: { title: '中国高等教育学历认证报告_中国高等教育学生信息网(学信网)' },
      component: () => import('@/views/index')
    },
    {
      path: '/preview',
      name: 'preview',
      meta: { title: '文件预览' },
      component: () => import('@/views/preview')
    },
    {
      path: '/',
      redirect: '/index'
    }
  ]
})
